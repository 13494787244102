<template>
  <div class="sign-in">
    <!-- splash or logo area -->
    <div class="sign-in-logo">
      <img class="logo" src="@/assets/logo_with_name.svg" alt="Selia" />
    </div>

    <div class="sign-in-header">
      <div class="heading-big" v-text="$translations['sign-in'].title" />
      <div class="sign-in-header-continue">
        <div
          class="body"
          v-text="$translations['sign-in']['sign-up-divider-text']"
        />
        <div
          @click="authChange('SignIn', 'SignUp', $route.query)"
          class="content-title text-purple cursor-pointer"
          v-text="$translations['sign-up'].continue"
        />
      </div>
    </div>

    <!-- Form -->
    <div class="sign-in-form">
      <!-- sign in with email and password -->
      <InputEmailField
        v-model="form.email"
        qa="sign_in_email"
        ref-name="sign_in_email"
        :label="$translations['sign-up'].labels.email"
        :lang="lang"
      />

      <PasswordField
        v-model="form.password"
        qa="sign_in_password"
        ref-name="sign_in_password"
        :label="$translations['sign-up'].labels.password"
        :lang="lang"
        class="margin-top"
      />
      <div
        qa="sign_in_forgot_password"
        class="forgot-password content-title cursor-pointer"
        @click="handleForgetPassword"
        v-text="$translations['forgot-password'].link"
      />

      <Button
        @clicked="emailLogin"
        class="margin-top"
        qa="sign_in_submit"
        :disable="formIsNotValid"
        :loading="loading && provider === 'email'"
        :text="$translations['sign-in']['submit-text']"
      />
    </div>

    <div class="divider">
      <div class="divider-line" />
      <div
        class="margin-x body-small"
        v-text="$translations['sign-in']['sign-in-provider']"
      />
      <div class="divider-line" />
    </div>

    <div class="social-buttons">
      <div v-for="(item, id) in providers" :key="id">
        <Button
          @clicked="socialLogin(item.id)"
          :type="item.type"
          :icon="item.icon"
          :svg-name="item.svg"
          :color="item.color"
          v-if="item.enabled"
        />
      </div>
    </div>

    <VersionComponent :app-version="$version" origin="user" />

    <!-- Kustomer -->
    <!--      <div class="sign-in-customer">
      <div class="body">
        {{ $translations['sign-in'].support.text }}
      </div>
      <div class="content-title cursor-pointer" @click="onOpenKustomer">
        {{ $translations['sign-in'].support.action }}
      </div>
    </div>-->
  </div>
</template>

<script>
import { validateEmail, Button, VersionComponent } from '@seliaco/red-panda'
import { SupportEvent, Acquisition } from '@/types/events'
import SignUpMixin from '@/mixins/sign-up-mixin'
import { InputEmailField, PasswordField } from '@seliaco/sea-otter'

export default {
  name: 'SignIn',
  components: {
    Button,
    InputEmailField,
    PasswordField,
    VersionComponent
  },
  data () {
    return {
      form: {
        firebase_uid: null,
        email: null,
        password: null,
        first_name: null,
        last_name: null
      },
      formError: {
        email: null
      }
    }
  },
  mixins: [SignUpMixin],
  mounted () {
    const url = this.$route.fullPath
    Acquisition.signIn({
      title: 'Selia | SignIn',
      url
    })
  },
  methods: {
    emailLogin () {
      this.loading = true
      this.provider = 'email'

      this.$auth
        .signInWithEmailAndPassword(
          this.form.email,
          this.form.password,
          this.$store.getters.language.lang
        )
        .then((response) => {
          return this.dispatchSignIn(response)
        })
        .catch((error) => {
          this.defaultError(
            error.message || this.$translations.error.auth.default
          )
        })
    },
    onOpenKustomer () {
      this.$kustomer.openChat(this.$store.getters.platform)
      SupportEvent.clickSupport({
        user: this.$store.getters['auth/user'],
        source: 'SignUp'
      })
    },
    hdleClickSignUp () {
      this.$store.commit('UPDATE_FORM', {
        email: this.form.email,
        password: this.form.password
      })
      this.$router.push({ name: 'SignUp', query: this.$route.query })
    },
    validateEmail () {
      this.formError.email =
        !validateEmail(this.form.email) &&
        this.$translations['form-error'].email
    },
    handleForgetPassword () {
      this.$router.push({ name: 'ForgotPassword', query: this.$route.query })
    }
  },
  computed: {
    formIsNotValid () {
      return !this.form.email || !this.form.password
    }
  }
}
</script>

<style lang="sass">
.sign-in
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px
  width: 100%
  max-width: 480px
  &-logo
    display: none
  &-header
    display: flex
    flex-direction: column
    align-items: center
    gap: 16px
    &-continue
      display: flex
      gap: 8px
      margin-bottom: 16px
.sign-in-form
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  max-width: 480px
  .heading-display
    margin: 16px 0 40px
    text-align: center
  .forgot-password
    margin-top: 16px
    text-align: end
    align-self: flex-end
.social-buttons
  display: flex
  gap: 24px
.divider
  display: flex
  align-items: center
  width: 100%
  &-line
    min-width: 50px
    height: 1px
    width: 100%
    background: var(--gray-20)
  .body-small
    white-space: nowrap
    color: var(--gray-50)
.link
  color: var(--purple)
  cursor: pointer
.terms-and-conditions
  line-height: 1
  text-align: center

@media (min-width: 840px)
  .sign-in
    &-logo
      display: block
</style>
