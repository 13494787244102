import { Acquisition } from '@/types/events'
import { Browser } from '@capacitor/browser'
import { loadAllClients as growthBook } from '@seliaco/growthbook'

export default {
  data () {
    return {
      loading: false,
      provider: null,
      providers: [
        {
          icon: 'icon-apple',
          id: 'apple',
          type: 'circle-outline',
          color: 'gray',
          enabled: true
        },
        {
          svg: 'google',
          id: 'google',
          type: 'circle-outline',
          color: 'gray',
          enabled: true
        }
        /*        {
          icon: 'icon-facebook',
          id: 'facebook',
          type: 'circle',
          color: 'gray',
          enabled: false
        } */
      ],
      lang: this.$store.getters.language.lang
    }
  },
  methods: {
    authChange (from, to, query) {
      this.$router.push({ name: to, query })
    },
    signUp () {
      if (!this.socialSignUp) {
        if (this.formIsNotValid) {
          this.$toast({
            severity: 'error',
            text: this.$translations.error['form-invalid']
          })
        }
      }

      this.loading = true

      const body = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        password: this.form.password,
        language: this.lang,
        firebase_uid: this.$route.query?.uid || null,
        company_code: this.form?.company_code || null
      }

      this.$auth
        .signUpWithEmailAndPassword('USER', body)
        .then(async (response) => {
          this.loading = false

          await this.$store.dispatch('auth/signIn', response)
          await this.$kustomer.signIn(
            this.$store.getters.platform,
            response.user.kustomer_jwt
          )
          const user = response.user
          const company = response.company

          growthBook({ user }).then(() => {
            this.$store.dispatch('growthBook/getGeneralConfig')
          })

          await this.$store.dispatch('settings/get')
          await this.$store.dispatch('sharedAccount/activation', {
            sharedAccountId: this.$route.query?.sharedAccountId,
            sharedAccountMemberId: this.$route.query?.sharedAccountMemberId
          })

          Acquisition.signupSuccessful({
            channel: this.$route.query.utm_campaign,
            message: this.$translations['sign-up']['create-success'],
            company_slug: company?.slug,
            user,
          })

          this.$toast({
            severity: 'success',
            text: this.$translations['sign-up']['create-success']
          })

          return this.$router.replace(
            { name: 'QuestionnaireOnboarding', query: this.$route.query },
            () => {}
          )
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message || 'Invalid sign in response'
          })
          this.loading = false
        })
    },
    socialLogin (provider) {
      this.loading = true
      this.provider = provider

      this.$auth
        .signInWithPopup(provider, this.lang)
        .then((response) => {
          if (response.credentials) {
            return this.dispatchSignIn(response)
          }

          if (response.do_sign_up) {
            this.socialSignUp = true
            this.form = response.form
            this.signUp()
          }
        })
        .catch(() => {
          return this.defaultError(this.$translations.error.auth.default)
        })
    },
    defaultError (text = this.$translations.error.default) {
      this.loading = false
      this.provider = null

      this.$toast({
        severity: 'error',
        text
      })
    },
    async dispatchSignIn (response) {
      // TODO: update this.setupConfig
      await this.setupConfig(response)

      await this.$router
        .replace({
          path: this.$route.query.next || '/',
          query: this.$route.query || null
        })
        .then(() => {
          if (this.$translations.language !== response.user.language) {
            location.reload()
          }
        })
    },
    async openTermsAndConditions () {
      await Browser.open({ url: process.env.VUE_APP_TERMS_AND_CONDITIONS_URL })
    },
    async setupConfig (response) {
      const user = response.user

      this.$kustomer
        .signIn(this.$store.getters.platform, user.kustomer_jwt)
        .then(() => {
          console.log('Kustomer login completed')
        })

      Acquisition.login({
        user,
        provider: this.provider
      })

      await growthBook({ user }).then(() => {
        console.log('growthBook login completed')
        this.$store.dispatch('growthBook/getGeneralConfig')
      })

      await this.$store.dispatch('auth/signIn', response)
      await this.$store.dispatch('settings/get')
      await this.$store.dispatch('sharedAccount/activation', {
        sharedAccountId: this.$route.query?.sharedAccountId,
        sharedAccountMemberId: this.$route.query?.sharedAccountMemberId
      })
    }
  }
}
